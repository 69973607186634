<template>
  <div class="drug" :class="this.radio == 1 ? 'xi' : 'zhong'">
    <hd-com></hd-com>
    <div class="banner">
      <div class="banner-text">
        <h3>药品库</h3>
        <p>YAO PIN KU</p>
      </div>
    </div>
    <div class="content">
      <div class="content-box clearfix">
        <div class="content-left">
          <div class="zhongxi">
            <el-radio-group v-model="radio" @change="changeHandler">
              <!--<el-radio label="0" border>全部</el-radio>-->
              <el-radio label="2" border>中成药</el-radio>
              <el-radio label="1" border>西药</el-radio>
            </el-radio-group>
          </div>
          <!--<div class="age">
                      <p>年龄</p>
                      <div>
                        <el-checkbox
                          :indeterminate="isIndeterminate"
                          v-model="checkAll"
                          @change="handleCheckAllChange"
                          >全选</el-checkbox
                        >
                        <el-checkbox-group
                          v-model="checkedAges"
                          @change="handleCheckedAgesChange"
                        >
                          <el-checkbox v-for="age in ages" :label="age" :key="age">{{
                            age
                          }}</el-checkbox>
                        </el-checkbox-group>
                      </div>
                    </div>-->
          <div class="sidenav">
            <h3 @click="searchDrug(0)">全部药品</h3>
            <el-menu class="el-menu-vertical-demo" :unique-opened="true">
              <el-submenu v-for="(sideNav, index) in drugCategoryList" :key="index" :index="index + ''">
                <template slot="title">
                  <span>{{ sideNav.categoryName }}</span>
                </template>
                <div class="subtitle" style="border: 1px solid #f2f2f2">
                  <div v-for="(subItem, index2) in sideNav.children" :key="index2">
                    <template v-if="subItem.children != null && subItem.children.length > 0">
                      <el-submenu :index="index + '-' + index2">
                        <template slot="title">
                          {{ subItem.categoryName }}
                        </template>
                        <el-menu-item @click="searchDrug(subItem.categoryId)" :index="subItem.categoryId + '2'">
                          <template slot="title"> 全部 </template>
                        </el-menu-item>
                        <el-menu-item v-for="(subItem2, i) in subItem.children" :key="i" :index="subItem2.categoryId + ''" @click="searchDrug(subItem2.categoryId)">
                          <template slot="title">
                            {{ subItem2.categoryName }}
                          </template>
                        </el-menu-item>
                      </el-submenu>
                    </template>
                    <template v-else>
                      <el-menu-item @click="searchDrug(subItem.categoryId)" :index="index + '-' + index2" style="padding-left: 30px !important">
                        <template slot="title">
                          {{ subItem.categoryName }}
                        </template>
                      </el-menu-item>
                    </template>
                  </div>
                </div>
              </el-submenu>
            </el-menu>
          </div>
        </div>
        <div class="content-right">
          <div class="result-tit">
            <p>
              为您找到 <span>{{ total }}</span
              ><em>个结果</em>
            </p>
          </div>
          <ul class="result-list">
            <li v-for="(drugItem, i) in DrugList" :key="i" class="resule-item clearfix">
              <div class="resule-left xy" :style="drugItem.category == '中成药' ? 'background-color:#b76c12;' : 'background-color:#1f6eb7;'">
                {{ drugItem.category == "中成药" ? "中" : "西" }}
              </div>
              <div class="resule-info left" @click="toDrugDetail(drugItem.hashKey)">
                <div class="clearfix">
                  <div class="drugName left">
                    <h3>
                      {{ drugItem.drugName }}
                      <span v-if="drugItem.drugTradeName != null && drugItem.drugTradeName != ''"> ({{ drugItem.drugTradeName }}) </span>
                    </h3>
                  </div>
                </div>
                <div class="indication">
                  <p>{{ drugItem.extend }}：<span v-html="drugItem.indication"></span></p>
                </div>
              </div>
              <div class="resule-star right">
                <p>
                  <span class="on-cpt" @click="onCompatibility(drugItem)">加入配伍栏</span>
                  <!-- <span v-else class="add-cpt" @click="onCompatibility(drugItem)">已加入</span> -->
                  <!-- / -->
                  <!-- <span v-if="!isCollection"
                                          class="on-col"
                                          @click="onCollection">收藏</span> -->
                  <!-- <span class="on-col" @click="onCollection(drugItem)">收藏</span> -->
                </p>
              </div>
            </li>
          </ul>
          <div id="page">
            <el-pagination background layout="prev, pager, next,total" :page-size="15" @current-change="handleCurrentChange" :current-page.sync="currentPage" :total="total"> </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <ft-com></ft-com>
    <rt-bar></rt-bar>
    <UpgradeMsgBox ref="upgradeMsgBox" @currentlyAvailable="getCurrentlyAvailable" :fullPath="$router.currentRoute.fullPath"></UpgradeMsgBox>
  </div>
</template>

<script>
import { Drug } from "../../components/Drug/Drug";
import hdCom from "../../components/header"; //引入组件
import ftCom from "../../components/footer"; //引入组件
import rtBar from "../../components/right";
import { Collection } from "../../components/collection/Collection";
import { addCompare, getCompatibilityList } from "../../utils/Compatibility";
const ageOptions = ["婴儿", "儿童", "成年", "老人", "孕妇"];
var searchId = 0;
var pageIndex = 1;
var categoryId = 0;
var keyword = "";
var radio = "2";
export default {
  name: "DrugBank",
  components: {
    hdCom,
    ftCom,
    rtBar,
  },
  data() {
    var drug = new Drug(this.TokenClient, this.Services.Drug);
    var collection = new Collection(this.TokenClient, this.Services.Statics);
    return {
      radio: "2",
      checkAll: false,
      checkedAges: [],
      ages: ageOptions,
      isIndeterminate: true,
      isCompatibility: false,
      isCollection: false,
      drugCategoryList: [],
      DrugList: [],
      total: 0,
      currentPage: 1, //初始页
      categoryName: "中成药",
      rloading: [], //loading遮罩
      drugDomain: drug,
      collectionDomain: collection,
      currentlyAvailable: false,
    };
  },
  mounted() {
    this.$bus.$emit("isShowChangeSize");
    this.$bus.$emit("select", "drug");
    this.initCategroy(this.categoryName);
    // categoryId = this.$route.query.categoryId
    keyword = this.$route.query.keyword;
  },
  methods: {
    handleCheckAllChange(val) {
      this.checkedAges = val ? ageOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedAgesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.ages.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.ages.length;
    },
    handleOpen(key, keyPath) {
      //console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      //console.log(key, keyPath);
    },
    onCompatibility: function (item) {
      var compatibility = getCompatibilityList();
      console.log(compatibility);
      if (compatibility.length > 0) {
        if (compatibility.some((i) => i.hashKey == item.hashKey && i.type == "1")) {
          this.$message.error("已加入用药审查！");
          return false;
        }
      }
      addCompare(item.hashKey, item.drugName, 1, "drug");
      this.$message.success("成功加入用药审查！");
      this.$bus.$emit("right", "reload");
    },
    onCollection: function (item) {
      var _this = this;
      var entityType = 0;
      if (item.category.indexOf("化学药品和生物制品") != -1) {
        entityType = 3;
      }
      if (item.category.indexOf("中成药") != -1) {
        entityType = 4;
      }
      _this.collectionDomain.Put(
        "drug",
        item.drugName,
        item.hashKey,
        item.drugId,
        entityType,
        function (data) {
          _this.$message({
            type: "success",
            message: "收藏成功!",
          });
        },
        function (err) {
          if (err.status === 400) {
            _this.$message.error("请勿重复收藏");
          }
        }
      );
    },
    //radio change事件
    changeHandler: function (value) {
      if (value == 2) this.categoryName = "中成药";
      if (value == 1 || value == 0) this.categoryName = "化学药品和生物制品";
      this.radio = value;
      this.initCategroy(this.categoryName);
      this.searchDrug(0);
    },
    //初始化药品分类
    initCategroy: function (categoryName) {
      var _this = this;
      _this.drugDomain.DrugCategories(
        categoryName,
        function (data) {
          _this.searchDrug(categoryId, pageIndex);
          // console.log(data)
          _this.drugCategoryList = data.data;
          _this.drugCategoryList.forEach((item) => {
            if (item.children && item.children.length > 0) {
              item.children.unshift({
                categoryId: item.categoryId,
                categoryName: "全部",
              });
            } else {
              item.children = [
                {
                  categoryId: item.categoryId,
                  categoryName: "全部",
                },
              ];
            }
          });
        },
        function (error) {
          console.log("药品分类请求失败!请刷新重试。", error);
        }
      );
    },
    //右侧药品列表获取
    searchDrug: function (cateId, isPage = false) {
      categoryId = cateId;
      this.rloading = this.openLoading();
      if (!isPage) pageIndex = 1;
      var category = "全部";
      if (this.radio == 1) category = "化学药品和生物制品";
      if (this.radio == 2) category = "中成药";
      ///api/Drug/Search/{category}/{searchId}/{pageIndex}/{categoryId}/{keyWord}
      if (cateId == undefined) cateId = 0;
      let params = `${category}/${pageIndex}/${cateId}`;
      if (keyword != "" && keyword != undefined) {
        params = params + `/${keyword}`;
      } else {
        params += "/%20";
      }
      var _this = this;
      _this.drugDomain.Drugs(
        params,
        function (data) {
          _this.DrugList = data.data.results;
          _this.total = data.data.dataTotal;
          searchId = data.msg;
          _this.rloading.close();
        },
        function (error) {
          console.log("药品列表请求失败!请刷新重试。", error);
        }
      );
      if (!isPage) {
        this.currentPage = 1;
      }
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    //分页点击事件
    handleCurrentChange: function (pageNum) {
      this.currentPage = pageNum;
      pageIndex = pageNum;
      this.searchDrug(categoryId, true);
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    getCurrentlyAvailable(data) {
      this.currentlyAvailable = data;
    },
    //跳转到详情
    toDrugDetail(hashKey) {
      ///api/Drug/{hashKey}
      if (!this.currentlyAvailable) {
        this.$refs.upgradeMsgBox.openTip();
        return;
      }
      this.$router.push({
        path: "/Drug/DrugDetail",
        query: {
          hashKey: hashKey,
          searchType: "drug",
        },
      });
    },
  },
  watch: {
    $route() {
      keyword = this.$route.query.keyword;
      this.searchDrug(categoryId, pageIndex);
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/header.css";
@import "../../assets/css/footer.css";

.xi .banner {
  height: 116px;
  background: url("../../assets/img/jbk_banner.png") center no-repeat;
}

.zhong .banner {
  height: 116px;
  background: url("../../assets/img/jbk_banner_zy.jpg") center no-repeat;
}

.banner-text {
  width: 1200px;
  margin: 0 auto;
}

.banner-text h3 {
  padding-top: 25px;
  font-size: 26px;
  font-weight: normal;
  color: #ffffff;
}

.banner-text p {
  font-size: 18px;
  color: #ffffff;
}

/*content*/
.content {
  background: #fcfcfc;
}

.content-box {
  width: 1300px;
  margin: 0 auto;
}

.content-left {
  float: left;
  width: 320px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.06);
}

.hot-search ul li {
  float: left;
  width: 33%;
  margin-top: 10px;
}

.hot-search ul li span {
  font-size: 14px;
  color: #666666;
  cursor: pointer;
}

.hot-search ul li span:hover {
  color: #1fb7a6;
}

.zhongxi {
  margin-bottom: 15px;
}

.zhongxi li {
  float: left;
  margin-right: 10px;
  padding: 3px 0;
  width: 100px;
  border: 1px solid #cccccc;
  text-align: center;
}

.age {
  margin-top: 30px;
}

.age ul li {
  width: 33.3%;
  float: left;
  margin: 10px 0;
}

.sidenav {
  margin-top: 30px;
}

.sidenav > h3 {
  width: 310px;
  padding-left: 10px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  color: #ffffff;
  background: #1fb7a6;
  font-weight: bold;
}

/*content-right*/
.content-right {
  float: right;
  width: 900px;
}

.result-tit {
  padding: 35px 0 25px;
  font-size: 28px;
  font-weight: bold;
  color: #222222;
}

.result-tit span,
.result-tit em {
  color: #00ab98;
  font-style: normal;
}

.result-list {
}

.resule-item {
  padding: 15px;
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: 2px 0 3px rgba(0, 0, 0, 0.08);
}

.resule-item:hover {
  box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.2);
}

.resule-info > div:nth-child(1) {
  cursor: pointer;
}

.resule-left {
  float: left;
  padding: 6px;
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
}

.resule-left.zy {
  background: #b76c12;
}

.resule-left.xy {
  background: #1fb7a6;
}

.cnname {
  max-width: 310px;
  margin-left: 10px;
  margin-right: 35px;
}

.cnname h3 {
  font-size: 18px;
  color: #333333;
}

.cnname p {
  font-size: 14px;
  color: #808080;
}

.jiantou {
  margin: 0 10px;
}

.jiantou p {
  font-size: 12px;
  color: #333;
}

.jiantou .jiantou-img {
  text-align: center;
  line-height: 30px;
}

.enname {
  width: 300px;
  font-size: 18px;
  color: #06a290;
  margin-left: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.symptom {
  width: 750px;
  margin: 15px 0 0 10px;
  color: #808080;
  font-size: 14px;
}

.symptom p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.resule-item:hover .resule-star {
  opacity: 1;
  transition: all linear 0.5s;
}

.resule-star {
  transition: all linear 0.5s;
  opacity: 0;
  /* margin-right: 30px; */
}

.resule-star i {
  font-size: 26px;
  color: #15aa9d;
}

/*药品库*/
.drugName {
  margin-left: 10px;
  width: 600px;
}

.drugName h3 {
  font-size: 18px;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.indication {
  width: 600px;
  margin: 15px 0 0 10px;
  color: #808080;
  font-size: 14px;
}

.indication p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.drug .resule-left {
  padding: 0px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
}

.resule-star p span {
  font-size: 15px;
  font-weight: normal;
  cursor: pointer;
  text-decoration: underline;
}

.resule-star p span.add-cpt {
  color: #b72e0e;
}

.resule-star p span.add-col {
  color: #1fb7a6;
}

/*page*/
#page {
  text-align: center;
  padding: 100px 0 30px;
}

.drug .el-radio.is-bordered {
  border-radius: 2px;
  width: 155px;
  text-align: center;
}

.drug .el-radio.is-bordered + .el-radio.is-bordered {
  margin-left: 5px;
}

.drug .el-radio {
  margin-right: 0;
}

.drug .el-checkbox-group {
  display: inline;
}

.drug .el-checkbox {
  width: 33.3%;
  margin: 10px 0;
}

.drug .el-checkbox__inner {
  width: 16px;
  height: 16px;
}

.drug .el-checkbox__inner::after {
  height: 9px;
  left: 5px;
  width: 4px;
}

.drug .el-badge__content.is-dot {
  height: 6px;
  width: 6px;
}

.drug .el-badge__content.is-fixed.is-dot {
  right: 0px;
  top: 11px;
}

.indication p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.xi .result-tit span,
.xi .result-tit em {
  color: #00ab98;
  font-style: normal;
}

.zhong .result-tit span,
.zhong .result-tit em {
  color: #b76c12;
  font-style: normal;
}

.xi .sidenav > h3 {
  width: 310px;
  padding-left: 10px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  color: #ffffff;
  background: #1fb7a6;
  font-weight: bold;
}

.zhong .sidenav > h3 {
  width: 310px;
  padding-left: 10px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  color: #ffffff;
  background: #b76c12;
  font-weight: bold;
}

/* **************** 左侧导航样式*/
.sidenav /deep/ .el-submenu__title > span {
  font-size: 16px;
  font-weight: 700;
}

.sidenav /deep/ .el-submenu__title i {
  color: #ffffff;
}

.xi /deep/ .el-submenu__title {
  padding: 0 !important;
  background: #1fb7a6;
  color: #ffffff;
  margin-top: 7px;
}

.zhong /deep/ .el-submenu__title {
  padding: 0 !important;
  background: #b76c12;
  color: #ffffff;
  margin-top: 7px;
}

.xi .subtitle /deep/ .el-submenu__title {
  padding-left: 30px !important;
  background: #fff;
  color: #333;
  margin-top: 0;
  height: 36px;
  line-height: 36px;
}

.xi .subtitle /deep/ .el-submenu__title:hover {
  background: rgba(31, 183, 166, 0.2);
}

.xi .subtitle /deep/ .el-submenu__title i {
  color: #000000;
}

.xi .el-menu-item.is-active,
.xi .el-menu-item:hover {
  color: #01c2ac;
  background: rgba(31, 183, 166, 0.2);
}

.zhong .el-menu-item.is-active,
.zhong .el-menu-item:hover {
  color: #b76c12;
  background: rgba(183, 108, 18, 0.2);
}

.el-submenu__title i {
  color: #ffffff;
}

.el-menu-item,
.el-submenu__title {
  height: 48px;
  line-height: 48px;
}

.subtitle {
  border: 1px solid #f2f2f2;
}

.subtitle .el-submenu__title i {
  color: #333333;
}

.subtitle .el-submenu__title {
  padding-left: 30px !important;
  background: #ffffff;
  color: #333333;
  margin-top: 0;
  height: 36px;
  line-height: 36px;
}

.el-submenu .el-menu-item {
  height: 36px;
  line-height: 36px;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #ffffff;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #1fb7a6;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  border-radius: 50%;
}

.el-pagination {
  font-weight: 500;
}

.el-submenu__title * {
  margin-left: 10px;
}

.el-submenu__title > span {
  font-size: 16px;
  font-weight: 700;
}

/**.头部按钮样式 */
.xi /deep/ .el-radio.is-bordered.is-checked {
  border-color: #01c2ac;
}

.xi /deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #01c2ac;
  background: #01c2ac;
}

.xi /deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #01c2ac;
}

.zhong /deep/ .el-radio.is-bordered.is-checked {
  border-color: #b76c12;
}

.zhong /deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #b76c12;
  background: #b76c12;
}

.zhong /deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #b76c12;
}

.zhong /deep/ .el-radio__inner:hover {
  border-color: #b76c12;
}
</style>
